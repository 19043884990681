import fetch from 'node-fetch'
import fetchWithRetryWrapper from 'fetch-retry'
import pLimit from 'p-limit'

// Expanded RFC3986 URL component encoder
export const encodeRFC3986URIComponent = (str) => {
    return encodeURIComponent(str).replace(
        /[!'()*/]/g,
        (c) => `%${c.charCodeAt(0).toString(16).toUpperCase()}`,
    )
}

const fetchRetry = fetchWithRetryWrapper(fetch, {
    retries: 5,
    retryOn: [429, 500, 501, 502, 503, 504, 520, 521, 522, 523, 524]
  })

const prodAPIBaseURL = process.env.VUE_APP_PROD_API ?? null

const apiBaseURL = ({ useProd = false }) => {
    if (useProd && prodAPIBaseURL) {
        return prodAPIBaseURL
    }
    return `${process.env.VUE_APP_API}`
}

// getTrainData
export const getTrainData = async () => {
    let result
    try {
        result = await fetch(`${process.env.VUE_APP_API}/getTrainData`, {
            method: 'GET'
        })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching train data: ${error}`)
    }

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.getTrainData ?? null
    }

    return []
}

export const getTrainDataWithTransitions = async ({ most_recent_sha256 = '' } = {}) => {
    let result
    try {
        result = await fetch(`${process.env.VUE_APP_API}/current-data-with-transitions${ most_recent_sha256 ? `/${encodeRFC3986URIComponent(most_recent_sha256)}` : ''}`, {
            method: 'GET'
        })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching train data with transitions: ${error}`)
    }

    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.result ?? null
    }

    return null
}

// current-data-with-transitions/:most_recent_sha256

// getHistoricalRoutePoints
export const getHistoricalRouteData = async ({ useProd = false }) => {
    let result
    try {
        result = await fetch(`${apiBaseURL({ useProd })}/getHistoricalRoutePoints`, {
            method: 'GET'
        })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching historical route data: ${error}`)
    }
    
    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.getHistoricalRouteData?.historical_points ?? null
    }

    return {}
}

export const getTrainMovement = async ({ train, useProd = false }) => {
    let result
    try {
        result = await fetchRetry(`${apiBaseURL({ useProd })}/getTrainMovements`, {
            retryDelay: function(attempt) {
              return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
            },
            method: 'POST',
            body: JSON.stringify({ trains: [train] }),
            headers: { 'Content-Type': 'application/json' }
          })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching train movement data: ${error}`)
    }
    
    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.results ?? null
    }

    return []
}

// Concurrency limit for fetches
const concurLimit = pLimit(20)

// getTrainMovements
// Render train 'movements' (way points) for transitions along a route based on historical route position data
export const getTrainMovements = async ({ trains, useProd = false }) => {
    const trainMoves = await Promise.all(trains?.map(train => {
        // Map the train inputs to an ordered list of train 'way points'
        return concurLimit(async () => getTrainMovement({ train, useProd }))
    }) ?? [])

    return trainMoves.flat()
}

// getRouteSampleMovement
// Fetch a recent completed train path for the specified route and train
export const getRouteSampleMovement = async ({ route, trainNum, destStationCodes, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/train-route-sample`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'POST',
                body: JSON.stringify({
                    route,
                    train: trainNum,
                    next_station_codes: destStationCodes?.join(',') ?? ''
                }),
                headers: { 'Content-Type': 'application/json' }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching route sample movement: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.result ?? null
        }

        return []
    })

    return result
}

// getStationSegmentPoints
// Fetch historical train location points based on train, route and the station destination code(s) of the source trains
export const getStationSegmentPoints = async ({ route, train, destStationCodes, previousStationCodes, maxResults = 1000, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/station-segment-points`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'POST',
                body: JSON.stringify({
                    route,
                    train,
                    next_station_codes: destStationCodes?.join(',') ?? '',
                    previous_station_codes: previousStationCodes?.join(',') ?? '',
                    max_results: maxResults
                }),
                headers: { 'Content-Type': 'application/json' }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching station segment points: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return { results: raw?.results ?? [] }
        }

        return { results: [] }
    })

    return result
}

// getWeatherForLocation
// INFO: https://www.weather.gov/documentation/services-web-api
// Retries (default = 3) due to possible 500 error from gridSearch (known issue)
export const getWeatherDetailsForLocation = async ({ lat, lng }, retries = 5) => {
    try {
        // Get the weather locality for the geo point
        const gridSearchResult = await fetch(`https://api.weather.gov/points/${lat},${lng}`, {
            method: 'GET'
        })

        const gridSearchStatus = gridSearchResult?.status
        if (gridSearchStatus !== 200) {
            throw(`Bad response from weather grid search endpoint: ${gridSearchStatus}`)
        }

        const gridSearchResultJSON = await gridSearchResult.json()

        const locationProps = gridSearchResultJSON?.properties?.relativeLocation?.properties

        const forecastURL = gridSearchResultJSON?.properties?.forecastHourly

        if (!forecastURL) {
            throw(`No forecast url provided for location!`)
        }

        // Get the weather locality forecast
        const forecastResult = await fetch(forecastURL)

        const forecaseStatus = forecastResult?.status
        if (forecaseStatus !== 200) {
            throw(`Bad response from weather forecast endpoint: ${forecaseStatus}`)
        }

        const info = (await forecastResult.json())?.properties?.periods?.[0]

        if (!info) {
            throw(`No data from weather forecast endpoint!`)
        }
        return {
            weatherDescription: `${info.shortForecast}, ${info.temperature}°${info.temperatureUnit}`,
            locationDescription: (locationProps ? `${locationProps.city}, ${locationProps.state}` : '')
        }
    } catch (error) {
        if (retries > 0) {
            return await getWeatherDetailsForLocation({ lat, lng }, retries - 1)
        }
    }
    
    return null
}

// getTrainNews
// Get recent train news objects from the server
export const getTrainNews = async () => {
    let result
    try {
        result = await fetch(`${process.env.VUE_APP_API}/news`, {
            method: 'GET'
        })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching train news data: ${error}`)
    }
    
    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.results ?? null
    }

    return []
}

// getStatistics
// Get data statistics from the server
export const getStatistics = async () => {
    let result
    try {
        result = await fetch(`${process.env.VUE_APP_API}/statistics`, {
            method: 'GET'
        })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching statistics data: ${error}`)
    }
    
    if (result?.status === 200) {
        const raw = await result.json()
        return raw ?? null
    }

    return null
}

export const getTrainHistory = async ({ trainObjectId, originTimestamp }) => {
    let result
    try {
        result = await fetchRetry(`${process.env.VUE_APP_API}/history/data/${trainObjectId}/${originTimestamp}/604800`, {
            retries: 5,
            retryDelay: function(attempt) {
              return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
            },
            method: 'GET'
          })
    } catch (error) {
        // TODO: use proper logger
        console.log(`Error fetching historical train data: ${error}`)
    }
    
    if (result?.status === 200) {
        const raw = await result.json()
        return raw?.result ?? null
    }

    return []
}

// getStationSegment
// Fetch pre-rendered station segment path data from the server
export const getStationSegment = async ({ route, origin, train, destination, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/station-segments`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'POST',
                body: JSON.stringify({
                    train,
                    route,
                    origin,
                    destination
                }),
                headers: { 'Content-Type': 'application/json' }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching station segment: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.result ?? null
        }

        return null
    })

    return result
}

// setStationSegment
// Upload pre-rendered station segment path data to the server
export const setStationSegment = async ({ apiKey, route, origin, destination, data, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/station-segments`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'PUT',
                body: JSON.stringify({
                    route,
                    origin,
                    destination,
                    data
                }),
                headers: { 'Content-Type': 'application/json', 'x-hasura-admin-secret': apiKey }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error posting station segment: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw ?? null
        }

        return null
    })

    return result
}

export const getSegmentsForRoute = async ({ route, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/route-segments`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'POST',
                body: JSON.stringify({
                    route,
                }),
                headers: { 'Content-Type': 'application/json' }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching route segments: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.result ?? null
        }

        return null
    })

    return result
}

export const getTrainsForSegment = async ({ route, origin, destination, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/route-segment-trains`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'POST',
                body: JSON.stringify({
                    route,
                    origin,
                    destination
                }),
                headers: { 'Content-Type': 'application/json' }
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching route segment trains: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.results ?? null
        }

        return null
    })

    return result
}

export const getRouteForTrain = async ({ train, useProd = false }) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/train-route/${train}`, {
                retryDelay: function(attempt) {
                return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'GET'
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching route for train '${train}': ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.result ?? null
        }

        return null
    })

    return result
}

export const getRoutes = async ({ useProd = false } = {}) => {

    const result = await concurLimit(async () => {
        let result
        try {
            result = await fetchRetry(`${apiBaseURL({ useProd })}/routes`, {
                retryDelay: function(attempt) {
                    return Math.pow(2, attempt) * 1000; // 1000, 2000, 4000
                },
                method: 'GET'
            })
        } catch (error) {
            // TODO: use proper logger
            console.log(`Error fetching train routes: ${error}`)
        }
        
        if (result?.status === 200) {
            const raw = await result.json()
            return raw?.results ?? null
        }

        return null
    })

    return result
}