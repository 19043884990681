const isValidDegreeMeasure = (deg) => deg >= -180.0 && deg <= 180.0

const inRadians = (deg) => (deg/180.0) * Math.PI

export const inDegrees = (rad) => (rad/Math.PI) * 180.0

const inMiles = (kilometers) => kilometers / 1.609344 // From: https://www.convertworld.com/en/length/mile/miles-to-km.html

export const milesToFeet = (miles) => miles * 5280

export const feetToMiles = (feet) => feet * 0.000189394

const meanEarthRadiusKilometers = 6371.009 // From: https://en.wikipedia.org/wiki/Great-circle_distance

// See: https://en.wikipedia.org/wiki/Degree_of_curvature
export const unitDegreeCurvatureRadius = 5729.58

// Compute central angle along shared 'great circle' swept by two points on a sphere (globe)
// Uses 'Haversine' function from: https://en.wikipedia.org/wiki/Great-circle_distance
// Assumes inputs are degree measures
export const computeCentralAngle = ({ lat1, long1, lat2, long2 }) => {
    // Validate inputs
    if (!isValidDegreeMeasure(lat1) ||
    !isValidDegreeMeasure(long1) ||
    !isValidDegreeMeasure(lat2) ||
    !isValidDegreeMeasure(long2)) {
        console.log(`computeCentralAngle: Value out of bounds! (${JSON.stringify({ lat1, long1, lat2, long2 }, null, 2)})`)
        return NaN
    }

    // Compute some partial expressions for Haversine function
    const lat1Rad = inRadians(lat1)
    const long1Rad = inRadians(long1)
    const lat2Rad = inRadians(lat2)
    const long2Rad = inRadians(long2)

    const deltaLat = lat1Rad > lat2Rad ? Math.abs(lat1Rad - lat2Rad) : Math.abs(lat2Rad - lat1Rad)
    const deltaLong = long1Rad > long2Rad ? Math.abs(long1Rad - long2Rad) : Math.abs(long2Rad - long1Rad)

    const sinSquaredDeltaLatOverTwo = Math.sin(deltaLat/2) ** 2
    const sinSquaredDeltaLongOverTwo = Math.sin(deltaLong/2) ** 2
    const sinSquaredSumLatsOverTwo = Math.sin((lat1Rad + lat2Rad)/2) ** 2

    const haversineRadicand = sinSquaredDeltaLatOverTwo + sinSquaredDeltaLongOverTwo * (1 - sinSquaredDeltaLatOverTwo - sinSquaredSumLatsOverTwo)

    const centralAngleRads = 2 * Math.asin(haversineRadicand ** 0.5)

    return inDegrees(centralAngleRads)
}

// Use the central angle swept for the two locations and Earth's 'mean earth radius' to compute the 'Great Circle' distance (miles)
export const computeGreatCircleDistance = ({ lat1, long1, lat2, long2 }) => {
    // Get central angle
    const centralAng = computeCentralAngle({ lat1, long1, lat2, long2 })
    if (isNaN(centralAng)) {
        return NaN
    }
    return inMiles((centralAng / 360.0) * 2 * Math.PI * meanEarthRadiusKilometers)
}

export const averageSpeed = (distance, timeMS) => {
    return (distance) / (timeMS / 1000.0 / 60 / 60) // convert millis to hours
}

export const headingThroughPoints = ({ lat1, long1, lat2, long2 }) => {
    // Simple cartesian trig applied to find the heading from { lat1, long1 } toward { lat2, long2 } where North = 0 degrees, East = 90 degrees, etc.
    // With { lat1, long1 } as the 'origin', determine the 'quadrant' and the relationship of the computed angle with the coordinate system
    const deltaLat = Math.abs(lat2 - lat1)
    const deltaLng = Math.abs(long2 - long1)
    const thetaDeg = inDegrees(Math.atan(deltaLat/deltaLng))
    let heading
    if ((long2 < long1) && (lat2 >= lat1)) {
        // 'NW-ish' heading
        heading = thetaDeg + 270
    } else if ((long2 >= long1) && (lat2 >= lat1)) {
        // 'NE-ish' heading
        heading = 90 - thetaDeg
    } else if ((long2 >= long1) && (lat2 < lat1)) {
        // 'SE-ish' heading
        heading = 90 + thetaDeg
    } else {
        // 'SW-ish' heading
        heading = 270 - thetaDeg
    }
    return heading
}

const vectorSum = (vectors) => {
    let sumX = 0
    let sumY = 0
    vectors?.forEach((vec) => {
        sumX += vec.x
        sumY += vec.y
    })
    return { x: sumX, y: sumY }
}

export const meanHeadingThroughPoints = ({ points }) => {
    // Given an ordered set of points [{ lat, lng }, ...], compute the "mean" heading of the path through all points
    // The "Mean" heading is a 2D unit-vector average described here: https://stackoverflow.com/a/5189336/2333933
    const headingPoints = [...points]
    const numPoints = headingPoints.length

    // Map headings to unit vectors: { x, y }
    const headingUnitVectors = []
    for (let headingIdx = 0; headingIdx < numPoints - 1; headingIdx++) {
        // Get the last two points from the sample
        const headingCalcPoints = headingPoints.slice(-2)
        const thisHeading = headingThroughPoints({
            lat1: headingCalcPoints[0].lat,
            long1: headingCalcPoints[0].lng,
            lat2: headingCalcPoints[1].lat,
            long2: headingCalcPoints[1].lng
        })
        if (thisHeading >= 0 && thisHeading < 90) {
            // 'NE-ish' heading
            headingUnitVectors.push({ x: Math.cos(inRadians(90 - thisHeading)), y: Math.sin(inRadians(90 - thisHeading))})
        } else if (thisHeading >= 90 && thisHeading < 180) {
            // 'SE-ish' heading
            headingUnitVectors.push({ x: Math.cos(inRadians(thisHeading - 90)), y: -1 * Math.sin(inRadians(thisHeading - 90))})
        } else if (thisHeading >= 180 && thisHeading < 270) {
            // 'SW-ish' heading
            headingUnitVectors.push({ x: -1 * Math.cos(inRadians(270 - thisHeading)), y: -1 * Math.sin(inRadians(270 - thisHeading))})
        } else {
            // 'NW-ish' heading
            headingUnitVectors.push({ x: -1 * Math.cos(inRadians(thisHeading - 270)), y: Math.sin(inRadians(thisHeading - 270))})
        }
        headingPoints.pop() // drop last point from sample
    }

    // Sum the unit vectors
    const meanHeadingVector = vectorSum(headingUnitVectors)

    // Compute the ATAN2 angle of the mean heading vector
    const meanHeadingRads = Math.atan2(meanHeadingVector.y, meanHeadingVector.x)
    const meanHeadingDegrees = inDegrees(meanHeadingRads)

    // Map to range: [0 (North), 360 (North)] degrees
    let headingMean = null

    if (meanHeadingDegrees >= 0 && meanHeadingDegrees <= 90) {
        // NE-ish
        headingMean = 90 - meanHeadingDegrees
    } else if (meanHeadingDegrees > 90 && meanHeadingDegrees <= 180) {
        // NW-ish
        headingMean = 270 + (180 - meanHeadingDegrees)
    } else if (meanHeadingDegrees < 0) {
        // SE-ish, SW-ish
        headingMean = 90 + Math.abs(meanHeadingDegrees)
    }
    return headingMean
}

export const headingDiffDegrees = ({ heading1, heading2 }) => {
    const absDiff = Math.abs(heading1 - heading2)
    const diff = (absDiff > 180) ? (360 - absDiff) : absDiff
    return diff
}

const radiusOfCurveChordFeet = ({ chordDistanceMiles, headingDiff }) => {
    // Use the forumula: r = chordDistance / (2 * sine(headingDiff / 2))
    if (headingDiff > 0) {
        return milesToFeet(chordDistanceMiles) / (2 * Math.sin( inRadians(headingDiff) / 2))
    }
    return Number.MAX_VALUE
}

export const curvatureDegreeChord = ({ chordDistanceMiles, headingDiff }) => {
    // Compute the radius of curvature given the data
    const radiusOfCurve = radiusOfCurveChordFeet({ chordDistanceMiles, headingDiff })
    // Compute the degree using the unit curve radious (constant)
    // Dc = 5729.58 / r 
    return unitDegreeCurvatureRadius / radiusOfCurve
}

export const linearInterpolateHeadings = ({ from, to, progress }) => {
    let absSweep = Math.abs(to - from)
    if (absSweep > 180) {
        absSweep = 360 - absSweep 
    }

    const toClockwise = from + absSweep
    const toCounterClockwise = from - absSweep

    // Check for discontinuity
    if (toClockwise > 360 || toCounterClockwise < 0) {
        // Discontinuity, translate to [-180, 180]
        const fromPrime = (from > 180) ? 360 - from : from
        const toPrime = (to > 180) ? 360 - to : to
        let resultPrime
        if (fromPrime <= toPrime) {
            resultPrime = fromPrime + (absSweep * progress)
        } else {
            resultPrime = fromPrime - (absSweep * progress)
        }
        // Translate back to [0, 360]
        return (resultPrime < 0) ? 360 - (Math.abs(resultPrime)) : resultPrime
    }

    if (from <= to) {
        return from + (absSweep * progress)
    }

    return from - (absSweep * progress)
}